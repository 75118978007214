import {
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  IconButton,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ContactButtons from "./ContactButtons";
import { RepeatIcon } from "@chakra-ui/icons";

const quotes = [
  "Sent from my iPhone.",
  "Did You Know: You can refresh this quote?",
  "Mistakes are the portals of discovery.",
  "Financial advisers, please stay away.",
  "🍝",
  "//TODO: Retire early.",
  "//TODO: Clear my to-dos.",
  "Ctrl C + Ctrl V.",
  "I really do not know how to fix your fax machine.",
  "Work Smart. Work Hard. Work Casual. In that order.",
  "Experience is the best teacher.",
  "Make it make sense.",
  "十年树木，百年树人。",
];

export default function Footer() {
  const [quote, setQuote] = useState(0);

  return (
    <Flex as="footer" maxWidth="720px" py="6" flexShrink="0" marginTop="auto">
      <Flex
        direction={{ base: "column", md: "row" }}
        w="100vw"
        align="center"
        justify="space-around"
      >
        <Stack
          my={{ base: "6", md: 0 }}
          direction={{ base: "column", md: "row" }}
          fontSize="sm"
          spacing={{ base: "2", md: "8" }}
          textAlign={{ base: "center", md: "start" }}
        >
          <Text>
            &copy; {new Date().getFullYear()} Fabian Chua –{" "}
            <em>{quotes[quote]}</em>
          </Text>
        </Stack>
        <Spacer />
        <ButtonGroup
          marginStart={{ md: "auto" }}
          color="gray.600"
          variant="ghost"
        >
          <IconButton
            as="a"
            onClick={() => setQuote(Math.floor(Math.random() * quotes.length))}
            icon={<RepeatIcon />}
          />
          <ContactButtons />
        </ButtonGroup>
      </Flex>
    </Flex>
  );
}
