import {
  Box,
  Flex,
  Spacer,
  IconButton,
  Button,
  Stack,
  Collapse,
  Link,
  useColorModeValue,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import Logo from "./Logo";
import { CloseIcon } from "@chakra-ui/icons";
import { FaHamburger as HamburgerIcon } from "react-icons/fa";
import { Link as ReactLink } from "react-router-dom";

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      as="header"
      boxShadow="base"
      bgColor={useColorModeValue(
        "rgba(238, 237, 236, 0.99)",
        "rgba(26, 32, 44, 0.99)"
      )}
      w="100%"
      position="sticky"
      top="0"
      zIndex="9999"
      justifyContent="center"
    >
      <Flex
        minH={"60px"}
        px={{ base: "1em", md: "0em" }}
        mx={{ base: "auto" }}
        borderBottom={1}
        maxW="720px"
        justify={{ base: "space-between" }}
      >
        <Flex align="center" w="100%">
          <Flex align="center" as="logo">
            <Link as={ReactLink} to="/">
              <Logo w="40px" />
            </Link>
          </Flex>
          <Spacer />
          <Flex
            display={{ base: "none", md: "flex" }}
            ml={10}
            justify="flex-end"
          >
            <DesktopNav />
            <ColorModeSwitcher />
          </Flex>
        </Flex>

        <Flex as="menu" display={{ base: "flex", md: "none" }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  return (
    <Stack direction={"row"} spacing={2}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Button
            as={ReactLink}
            to={navItem.href}
            _hover={{ bg: navItem.hoverColor, color: "gray.50" }}
            variant="ghost"
          >
            {navItem.label}
          </Button>
        </Box>
      ))}
    </Stack>
  );
};

const LightMode = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Button onClick={toggleColorMode}>
      "Hey Siri,
      {colorMode === "light" ? ' lights off please."' : ' lights on please."'}
    </Button>
  );
};

function MobileNav() {
  return (
    <Stack p={4} display={{ md: "none" }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
      <Stack>{LightMode()}</Stack>
    </Stack>
  );
}

const MobileNavItem = ({ label, emoji, hoverColor, href }) => {
  return (
    <Stack spacing={4} justifyContent="center">
      <Button
        variant="ghost"
        as={ReactLink}
        to={href}
        py={2}
        bgColor={hoverColor}
        justify={"center"}
        align={"center"}
      >
        {label} {emoji}
      </Button>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "About",
    href: "about",
    hoverColor: "rgba(92, 122, 94, 0.8)",
  },
  {
    label: "Projects",
    href: "projects",
    hoverColor: "rgba(122, 92, 153, 0.8)",
  },
  {
    label: "Experiences",
    href: "experiences",
    hoverColor: "rgba(144, 81, 78, 0.8)",
  },
];
