import {
	Flex,
	Heading,
	Image,
	Link,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'
import me from '../assets/images/me.avif'
import PageHeader from '../components/PageHeader'
import './About.css'
const ChineseText = (props) => {
	return (
		<Text
			fontFamily="New Tegomin"
			color=" #aa381e"
			ml={['-20px', '-16px']}
			d={['block', 'inline']}
			{...props}
		>
			{props.children}
		</Text>
	)
}
const Speech = (props) => {
	return (
		<Text
			fontFamily="Covered By Your Grace"
			color="#fb9912"
			py={'2'}
			{...props}
		>
			{props.children}
		</Text>
	)
}
const Avatar = (props) => {
	return (
		<Image
			height="12rem"
			borderRadius={{ base: '20px', md: 'full' }}
			d="inline-block"
			mr={{ base: '0', md: '6' }}
			mb="6"
			objectFit="cover"
			style={{
				boxShadow:
					'0 4px 8px 0 rgba(0, 0,0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
			}}
			{...props}
		/>
	)
}
export default function About() {
	return (
		<div data-aos="fade-up">
			<PageHeader
				bgColor={useColorModeValue('#AED1B6', '#5C7A5E')}
				title="about"
				subtitle="interest, hobbies, project"
			/>
			<Flex maxWidth="720px" direction="column" data-aos="fade-up">
				<Flex direction={{ base: 'column', md: 'row' }}>
					<Avatar src={me} alt="me" />
					<Flex direction="column">
						<Heading fontSize="4xl" mb={'4'} d="inline-block">
							Fabian Chua <ChineseText>「蔡昇融」</ChineseText>
						</Heading>
						<Text fontSize="xl">
							He is a penultimate Software Engineering undergraduate at
							Singapore Institute of Technology. He has various interests in
							tech, but his primary focus is in frontend and tech for public
							good.
							<br />
							<Speech>Ok let me jump out of third person already.</Speech>
						</Text>
					</Flex>
				</Flex>
				<Text fontSize="xl">
					Hi, Fabian here! Personally, I am an avid learner who is passionate
					about making impactful designs to simplify our daily lives. Simply
					put, I enjoy solving complex problems with good design that just make
					things make sense.
					<Speech>Make it make sense man.</Speech>
				</Text>
				<Text fontSize="xl" mb="4">
					Most recently, I completed an internship with{' '}
					<Link color={'#19C1F3'}>GovTech</Link> where I served as a Product
					Delivery Management intern. Last summer ☀️, I undertook an internship
					with <Link color="#A100FF">Accenture</Link> working on a Design
					Language System for a public sector agency. Prior to that, I was a
					Software Engineer intern with <Link color="#6b21d5">Sponsee</Link> and
					was part of the <Link color="green">Army Digitalization Office</Link>.
					I also volunteered at <Link color="#2c5971">CallHomeSG</Link>, a
					tech-for-good initiative, assisted in the development of the web app.
				</Text>
				<Text fontSize="xl" mb="4">
					Outside of tech, I enjoy the company of people, good food and football
					on the weekends – both playing and watching (Manchester United).
				</Text>
			</Flex>
		</div>
	)
}
