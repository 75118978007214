import React from "react";
import {
  Flex,
  Image,
  Heading,
  Text,
  Button,
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  TagRightIcon,
  Spacer,
  ButtonGroup,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import ContactButtons from "../components/ContactButtons";
import { FaFeatherAlt } from "react-icons/fa";
import me from "../assets/images/me_home.avif";
import { Tooltip } from "../components/Tippy";

//TODO: use next.js for SSR(?)

function LinkTag(props) {
  return (
    <WrapItem>
      <Tag
        size="lg"
        borderRadius="full"
        as={Link}
        bgColor="rgba(103,115,96, .8)"
        _hover={{
          bgColor: "rgba(103,115,96, .6)",
        }}
        color="gray.100"
        to={props.to}
        fontWeight="bold"
      >
        <TagLabel>{props.children}</TagLabel>
        <TagRightIcon as={ChevronRightIcon} />
      </Tag>
    </WrapItem>
  );
}

function ContactMeButton(props) {
  return (
    <Button
      rightIcon={<FaFeatherAlt />}
      as="a"
      bgColor="#fb9912"
      borderRadius="20px"
      fontWeight="bold"
      _hover={{
        bgColor: "rgba(251, 153, 18, .7)",
      }}
      color="gray.100"
      {...props}
    >
      Contact Me
    </Button>
  );
}

export default function Home() {
  return (
    <div data-aos="fade-up">
      <Flex w="100%" h={["2.5vh", "2.5vh", "5vh"]} />
      {/* additional head space */}
      <Flex maxWidth="720px" direction="column">
        <Flex d="block" w="full" mb={"8"}>
          <Image
            boxSize="125px"
            src={me}
            alt="a photo of Fabian"
            borderRadius="full"
            border="5px outset rgba(251, 153, 18, 0.7)"
            objectFit="cover"
          />
        </Flex>
        <Heading as="h3">Hey! I'm Fabian. 👋🏼</Heading>
        <Text fontSize={["2xl", "3xl"]} my="6">
          A software developer based in{" "}
          <Tooltip label="Nope, I'm not a Crazy Rich Asian.">
            Singapore 🇸🇬.
          </Tooltip>
          <br /> Passionate in creating impactful products and implementing
          designs that make sense.
        </Text>
        <Wrap w="100%" spacing={["12px", "20px"]}>
          <LinkTag to="/about">about</LinkTag>
          <LinkTag to="/projects">projects</LinkTag>
          <LinkTag to="/experiences">experiences</LinkTag>
        </Wrap>
        <Flex align="center" mt="24px">
          <ButtonGroup color="gray.600" variant="ghost">
            <ContactButtons />
          </ButtonGroup>
        </Flex>
      </Flex>
    </div>
  );
}
