import React from 'react';
import {Box} from '@chakra-ui/react'
import fc from '../assets/logo.svg'

export default function Logo(props:string) {
  return (
    <Box {...props}>
      <img src={fc} alt='logo'/>
    </Box>
  )
}
