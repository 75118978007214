import React from "react";
import { Flex, useColorModeValue } from "@chakra-ui/react";
import ProjectCard from "../components/ProjectCard";
import PageHeader from "../components/PageHeader";
import { projects } from "./Database";

export default function Projects() {
  return (
    <div data-aos="fade-up">
      <PageHeader
        bgColor={useColorModeValue("#C4AEDC", "#7A5C99")}
        title="projects"
        subtitle="personal development & community projects"
      />
      <Flex maxWidth="720px" direction="column">
        {projects.map((project) => {
          return (
            <ProjectCard
              url={project.url}
              image={project.image}
              avatar={project.avatar}
              date={project.date}
              title={project.title}
              oneLiner={project.oneLiner}
              description={project.description}
              button={project.button}
              skills={project.skills}
            />
          );
        })}
      </Flex>
    </div>
  );
}
