import React from "react";
import { Flex, useColorModeValue } from "@chakra-ui/react";
import ExperiencesCard from "../components/ExperiencesCard";
import PageHeader from "../components/PageHeader";
import { experiences } from "./Database";

export default function Experiences() {
  return (
    <div data-aos="fade-up">
      <PageHeader
        bgColor={useColorModeValue("#DCAEAE", "#90514E")}
        title="experiences"
        subtitle="organizations I worked with."
      />
      <Flex maxWidth="720px" direction="column">
        {experiences.map((experience) => {
          return (
            <ExperiencesCard
              company={experience.company}
              img={experience.companyLogo}
              date={experience.date}
              role={experience.role}
              description={experience.description}
              testimonial={experience.testimonial}
            />
          );
        })}
      </Flex>
    </div>
  );
}
