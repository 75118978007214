import {
  Flex,
  Heading,
  Text
} from '@chakra-ui/react'

export default function PageHeader (props) {
  return (
    <>
      <Flex w='100%' h={['2.5vh', '2.5vh', '5vh']} />
      <Heading
        as='h1'
        size='lg'
        mb={'4'}
        w='fit-content'
        borderRadius='20px'
        padding='4px 12px 4px'
        backgroundColor={props.bgColor}
      >
        {props.title}
      </Heading>
      {/* //TODO: move heading to header or the headspace */}
      <Text fontSize='xl' color='gray' mb={'4'}>
        {props.subtitle}
      </Text>
    </>
  )
}