import * as React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import theme from './theme'
import Container from './components/Container'
import Home from './pages/Home'
import About from './pages/About'
import Projects from './pages/Projects'
import Experiences from './pages/Experiences'
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'focus-visible/dist/focus-visible'

AOS.init()

export const App = () => (
	<ChakraProvider theme={theme}>
		<Router>
			<Container>
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/about" component={About}></Route>
					<Route path="/experiences" component={Experiences}></Route>
					<Route path="/projects" component={Projects}></Route>
				</Switch>
			</Container>
		</Router>
	</ChakraProvider>
)

//TODO: maybe add a fun section
