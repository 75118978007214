import React from 'react'
import {
  Flex,
  Image,
  List,
  Text,
  ListItem,
  ListIcon,
  useColorModeValue,
  Heading,
  Box,
  Button,
  Spacer
} from '@chakra-ui/react'
import { AttachmentIcon, CheckCircleIcon } from '@chakra-ui/icons'
import { Tooltip } from './Tippy'

export default function ExperiencesCard (props) {
  const value = useColorModeValue('white', 'gray.800')

  function DateText (props) {
    return (
      <Text fontFamily='IBM Plex Mono' fontSize={'xl'}>
        {props.children}
      </Text>
    )
  }

  return (
    <Flex
      mb='4'
      direction='column'
      h='fit-content'
      maxW='720px'
      borderRadius='20px'
      data-aos='fade-up'
    >
      <Flex
        pos='sticky'
        top={['70px', '60px']}
        w='100%'
        d='row'
        bgColor={value}
        py='5'
      >
        <DateText>{props.date}</DateText>
        <Flex mt='2'>
          <Image
            borderRadius='full'
            maxW='50px'
            maxH='50px'
            src={props.img}
            mr='3'
            objectFit='cover'
          />
          <Heading fontSize='xl' alignSelf='center'>
            {props.company}
            <br />
            <Heading fontSize='xl' color='gray.600'>
              {props.role}
            </Heading>
          </Heading>
          <Spacer/>
          <Tooltip label={props.testimonial.label}>
            <Button
              variant='outline'
              alignSelf='center'
              as='a'
              href={props.testimonial.url}
              display={props.testimonial.present ? 'flex' : 'none'}
            >
              <AttachmentIcon />
            </Button>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex as={Box} py='4' borderRadius='0 0 20px 20px'>
        <List spacing={4} fontSize='md'>
          {props.description.map(p => {
            return (
              <ListItem d='flex' alignItems='center'>
                <ListIcon as={CheckCircleIcon} color='green.500' />
                {p}
              </ListItem>
            )
          })}
        </List>
      </Flex>
    </Flex>
  )
}
