import { extendTheme } from '@chakra-ui/react'

const config = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
}

const theme = extendTheme({
	config,
	focusBorderColor: {
		default: '#fb9912',
	},
	fonts: {
		body: 'Nunito, sans-serif',
		date: 'IBM Plex Mono, monospace',
	},
	backgroundColor: {
		light: 'rgba(238, 237, 236)',
		dark: 'rgba(26, 32, 44)',
	},
	colors: {
		white: 'rgba(248, 247, 247)',
		light: '#faf9f8',
		dark: '#fb9912',
	},
})

export default theme
