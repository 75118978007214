import { IconButton } from "@chakra-ui/react";
import {
  FaGithub,
  FaLinkedin,
  FaTelegramPlane,
  FaEnvelope,
  FaFileAlt,
} from "react-icons/fa";

import "./ContactButton.css";

export default function ContactButtons() {
  return (
    <>
      {Buttons.map((button) => {
        return (
          <IconButton
            as="a"
            href={button.href}
            aria-label={button.label}
            icon={button.icon}
            _hover={{ bg: button.hoverBg, color: button.hoverColor }}
          />
        );
      })}
    </>
  );
}

const Buttons = [
  {
    href: "https://www.linkedin.com/in/fabianchua6/",
    label: "LinkedIn",
    icon: <FaLinkedin />,
    hoverBg: "#0e65c2",
    hoverColor: "#eeedec",
  },
  {
    href: "https://github.com/fabianchua6/",
    label: "GitHub",
    icon: <FaGithub />,
    hoverBg: "#24292e",
    hoverColor: "#eeedec",
  },
  {
    href: "https://t.me/fabianchua6",
    label: "Telegram",
    icon: <FaTelegramPlane />,
    hoverBg: "#29a8eb",
    hoverColor: "#eeedec",
  },
  {
    href: "https://read.cv/fabianchua6",
    label: "Read my CV",
    icon: <FaFileAlt />,
    hoverBg: "#eeedec",
    hoverColor: "#222222",
  },
];
