const projects = [
	// TODO: grab hackathon
	{
		url: 'https://pandaborderless-git.vercel.app/',
		image: require('../assets/images/pandaBorderless.png'),
		avatar: require('../assets/images/pandaBorderless_avatar.png'),
		date: 'January 2023',
		title: 'PandaBorderless',
		oneLiner: `Awarded Bybit's Most Reliable Fintech Solution in NUS Fintech Month Hackathon.`,
		description:
			'PandaBorderless is a decentralized cross-border payment platform that aims to address the pain points of slow and expensive transactions.',
		skills: [
			{ skill: 'Front-End Dev' },
			{ skill: 'React', color: '#61dafb', fontColor: '#222222' },
			{ skill: 'Solidity', color: '#0B131D', fontColor: 'white' },
			{ skill: 'JavaScript', color: '#f0db4f', fontColor: '#222222' },
		],
	},
	{
		url: 'https://sit-hackrift.netlify.app',
		image: require('../assets/images/sitHackRift.png'),
		avatar: require('../assets/images/sitHackRift_avatar.png'),
		date: 'October 2022',
		title: 'SIT HackRift 2022',
		oneLiner: `SIT's inaugural hackathon, in partnership with GovTech.`,
		description:
			'Landing page for SIT HackRift 2022, a 48-hour hackathon in partnership with GovTech.  This hackathon aims to leverage on the power of technology to solve real-world problems faced by citizens of Singapore.',
		skills: [
			{ skill: 'Front-End Dev' },
			{ skill: 'React', color: '#61dafb', fontColor: '#222222' },
			{ skill: 'JavaScript', color: '#f0db4f', fontColor: '#222222' },
		],
	},
	{
		url: 'https://callhome.sg',
		image: require('../assets/images/callHome.png'),
		avatar: require('../assets/images/callHome_avatar.png'),
		date: 'September 2020',
		title: 'Call Home SG',
		oneLiner: 'Free IDD calls for foreign workers',
		description:
			'Call Home is an initiative that helps to bridge the connectivity gap between migrant workers and their loved ones.  Funded by donors, users can make free IDD calls to their loved ones in these times of difficulties.',
		skills: [
			{ skill: 'Front-End Dev' },
			{ skill: 'React', color: '#61dafb', fontColor: '#222222' },
			{ skill: 'TypeScript', color: '#3178c6', fontColor: 'white' },
		],
	},
	{
		url: 'https://colab.research.google.com/drive/109QGB7xSoN6tqLd79sCIxHqfYRc1e7tq?usp=sharing',
		image: require('../assets/images/sentimentalAnalysis.png'),
		avatar: require('../assets/images/sentimentalAnalysis.png'),
		date: 'October 2020',
		title: 'Sentiment Analysis of COVID-19 Tweets',
		oneLiner: 'Hackwagon Data Science Project',
		description:
			'Sentimental analysis on how Singaporeans are feeling towards critical COVID-19 Events. Datasets are scraped from Twitter and analysed using machine-learning models.',
		skills: [
			{ skill: 'Data Analysis', color: 'blue', fontColor: 'white' },
			{ skill: 'Python', color: '#356f9f', fontColor: 'white' },
		],
	},
	{
		url: 'https://t.me/ArmyCAT1/',
		image: require('../assets/images/armyCAT1.png'),
		avatar: require('../assets/images/armyCAT1_avatar.png'),
		date: 'November 2020',
		title: 'Army CAT 1 Info Channel',
		oneLiner: 'JavaScript Web-Scraping Tool',
		description:
			'Web-scraping bot using JavaScript that disseminates useful weather information through a Telegram Channel to army-wide users, ensuring trainings are conducted under safe weather conditions.',
		skills: [
			{ skill: 'Web-Scraper' },
			{ skill: 'JavaScript', color: '#f0db4f', fontColor: '#222222' },
		],
	},
	{
		url: 'https://t.me/owadiobot/',
		image: require('../assets/images/owadioBot.png'),
		avatar: require('../assets/images/owadioBot_avatar.png'),
		date: 'March 2020',
		title: 'OWADIO Bot',
		oneLiner: 'Telegram Chatbot using Python',
		description:
			'My first project made with Python. It is a chatbot that allows users to countdown to their ORD date (end of National Service for all men in Singapore).',
		skills: [{ skill: 'Python', color: '#356f9f', fontColor: 'white' }],
	},
]

const experiences = [
	{
		role: 'Deliver Manager / Software Engineering Intern',
		company: 'Government Technology Agency',
		companyLogo: require('../assets/images/govTechLogo.png'),
		date: '05/2023 - 09/2023',
		description: [
			"Spearheaded the planning and facilitation of discovery activities for emerging Contact Centre product, refining the product's Objective and Key Results, vision, and strategic roadmap",
			'Directed the design and development of Case Management System features used by over 100 staffs, handling over 100,000 monthly enquiries; this resulted in a 20% increase in customer satisfaction scores',
			'Facilitated scrum ceremonies for an agile team, ensuring efficient workflow and maintaining consistent communication with key stakeholders to ensure effective product delivery',
		],
		testimonial: {
			present: false,
		},
	},
	{
		role: 'Front End Intern',
		company: 'Accenture',
		companyLogo: require('../assets/images/accentureLogo.png'),
		date: '05/2022 - 08/2022',
		description: [
			'Developed reusable web components in Design System to increase code reusability and scalability.',
			'Improved efficiency and readability of project code through code refactoring, optimization, and documentation.',
			'Maintained web components and rectified design discrepancies, reducing technical debt by 40%.',
			'Conducted quality assurance and testing to ensure the Design System is free of defects.',
		],
		testimonial: {
			present: true,
			url: require('../assets/files/accentureTestimonial.pdf'),
			label: 'Testimonial',
		},
	},
	{
		role: 'Software Developer Intern',
		company: 'Sponsee',
		companyLogo: require('../assets/images/sponseeLogo.png'),
		date: '06/2021 - 12/2021',
		description: [
			'Developed full-stack iOS & Android mobile application connecting businesses with influencers.',
			"Overhauled the UX/UI design of the company's website and developed a mobile-responsive website that is clean and intuitive. ",
			'Developed and implemented SEO strategies to improve  organic traffic and search ranking.',
		],
		testimonial: {
			present: false,
		},
	},
	{
		role: 'Army Digital Specialist',
		company: 'Army Digitalisation Office',
		companyLogo: require('../assets/images/armyDO.png'),
		date: '05/2020 - 05/2021',
		description: [
			'Awarded Best Soldier of the Month by Chief Commander HQ 9th Division.',
			'Involved in ideation of innovative solutions to digitalise Army wide processes. Developed a web-scraping Telegram bot to disseminate useful weather information.',
			'Development of cross-platform mobile application and front end UI/UX design.',
		],
		testimonial: {
			present: true,
			url: require('../assets/files/soldierOfTheMonth.pdf'),
			label: 'Award',
		},
	},
	{
		role: 'Finance Data Admin',
		company: 'Heinemann Asia Pacific',
		companyLogo: require('../assets/images/hap.png'),
		date: '05/2019 - 07/2019',
		description: [
			'Responsible for cleaning up Master Data used across Heinemann Asia Pacific which includes regions such as Singapore, Malaysia, China, Hong Kong and Australia.',
			'Accurate and updated database allows for payments to be made automatically on a periodically basis. Reducing over hundreds of manual labour hours/month.',
			'Accounts Receivable daily sales data entry.',
		],
		testimonial: {
			present: true,
			url: require('../assets/files/heinemannTestimonial.pdf'),
			label: 'Testimonial',
		},
	},
	{
		role: 'Data Maintenance Intern',
		company: 'OCBC Bank',
		companyLogo: require('../assets/images/ocbc.png'),
		date: '08/2018 - 02/2019',
		description: [
			'Partake in a project to fully automate process to cope with the surging volume. Successfully automated data entry of over 300 forms/day using text-recognition.',
			'In charge of managing document flow in a process where customers open bank accounts using SingPass’s MyInfo digitally. Reducing hundreds of hours every week that goes into processing paperwork.',
			'Improved and streamlined processes that are used on daily basis by incorporating automation methods such as macro and VBA. As a result, increased efficiency by 60% reducing unit processing time and saved cost.',
			'Carried out User-Acceptance-Testing, ensuring products are free of defects and capable of handling intensive tasks in real-world scenarios. Responsible for highlighting views and concerns for my team as well as log and track defects to respective departments to ensure it is solved.',
		],
		testimonial: {
			present: false,
		},
	},
]

export { projects, experiences }
