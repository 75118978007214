import { Flex } from '@chakra-ui/react'
import Header from './Header'
import Footer from './Footer'

const Container = ({ children, ...props }) => {
  return (
    <Flex
      direction='column'
      justify='flex-start'
      minHeight='100vh'
      alignItems='center'
    >
      <Header />
      <Flex h={'2.5vh'} />
      <Flex
        p={{ base: '1em', sm: '0', md: '0' }}
        minW={{ base: '100%', sm: '720px' }}
      >
        {children}
      </Flex>

      <Footer />
    </Flex>
  )
}

export default Container
