import React from "react";
import {
  useDisclosure,
  Collapse,
  Link,
  Avatar,
  Flex,
  Stack,
  Spacer,
  Box,
  Button,
  Heading,
  Text,
  Image,
  useColorModeValue,
  ButtonGroup,
  Wrap,
  Tag,
} from "@chakra-ui/react";
import "./ProjectCard.css";

const CustomTag = (props) => (
  <Tag size="sm" borderRadius="full" {...props}></Tag>
);

export default function ProjectCard(props) {
  const { isOpen, onToggle } = useDisclosure();

  const [show, setShow] = React.useState(false);

  function handleClick() {
    setShow(!show);
    onToggle();
  }

  const value = useColorModeValue("rgb(248,247,247,.95)", "rgb(26,33,44,.95)");

  return (
    <>
      <Box mb="8" className="card" data-aos="fade-up">
        <Box
          maxWidth="720px"
          className="card"
          borderRadius="20px"
          overflow="hidden"
          minHeight="150px"
          onClick={handleClick}
          _hover={{ cursor: "pointer" }}
        >
          <Collapse in={isOpen} offsetY="20px" width="100%" overflow="hidden">
            <Image objectFit="cover" src={props.image} bgColor={value} />
          </Collapse>
          <Box width="100%" p="4" bgColor={value}>
            <Flex align="center">
              <Avatar
                src={props.avatar}
                size="sm"
                display={show ? "none" : "inline-flex"}
                mr="1em"
              />
              <Heading fontSize={["xl", "2xl"]}>{props.title}</Heading>
            </Flex>
            <Text fontSize="md" my="6px" color="gray.500">
              {props.oneLiner}
            </Text>
            <Collapse in={isOpen} isTruncated startingHeight={20}>
              <Text noOfLines={isOpen ? null : 1}>{props.description}</Text>
            </Collapse>
            <Stack
              direction={["column", "row"]}
              mt="4"
              align="center"
              justifyItems="left"
            >
              <Text
                fontSize="sm"
                color="gray.500"
                alignSelf={["flex-start", "center"]}
              >
                {props.date}
              </Text>
              <Wrap>
                {props.skills.map((p) => {
                  return (
                    <CustomTag bgColor={p.color} color={p.fontColor}>
                      {p.skill}
                    </CustomTag>
                  );
                })}
              </Wrap>
              <Spacer />
              <ButtonGroup w={["100%", "auto"]}>
                <Button
                  w={["100%", "auto"]}
                  size={"sm"}
                  variant={show ? "ghost" : "outline"}
                  textDecoration={show ? "underline" : "none"}
                  onClick={handleClick}
                >
                  Show {show ? "Less" : "More"}
                </Button>
                <Button
                  w={["100%", "auto"]}
                  as={Link}
                  size="sm"
                  colorScheme="twitter"
                  href={props.url}
                  target="_blank"
                >
                  {props.button ? props.button : "Check it out!"}
                </Button>
              </ButtonGroup>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
}
